<template>
	<div>
		<div
			:class="{
				pa15: $vuetify.breakpoint.smAndDown,
				'pa20 palr5': $vuetify.breakpoint.mdAndUp,
			}"
		>
			<div class="right-side-pannel-quiz" :class="{ 'd-none': $vuetify.breakpoint.smAndDown }">
				<div class="assistant-container">
					<img id="micro-picture-assistant" src="@/assets/icons/assistant.svg" class="assistant-icon d-none" alt="" />
					<v-btn class="indicator-section" depressed>ASSISTANT</v-btn>
				</div>
				<div id="picture-assistant-div" class="img-assistant-pannel-right-div">
					<img src="@/assets/icons/assistant.svg" class="img-assistant-pannel-right" alt="" />
					<p class="mt20">Réponds à la question pour commencer</p>
				</div>
			</div>
			<div>
				<div class="d-flex align-center flex-row mb30">
					<div class="indicatorIcons-container">
						<v-btn class="indicator-section" depressed> Partie Quiz </v-btn>
					</div>
					<v-progress-circular
						rotate="90"
						size="30"
						width="7"
						class="ml-2"
						:value="(currentScore / questions.length) * 100"
						color="#34e5c8"
					>
					</v-progress-circular>
					<span id="flashcount" class="ml-2 ortho-text-green-flash ortho-bold"
						><span class="fs20">{{ currentScore * 10 }}</span
						><img class="lighteningIcons" src="@/assets/icons/lightening-green.svg" /> /{{ questions.length * 10 }}
					</span>
				</div>
				<zoom-center-transition group>
					<v-row v-for="(questionB, index) in questions.slice(0, countFormat2)" :key="questionB._id">
						<v-col md="6" sm="12" cols="12">
							<div
								:id="'question-answer-container' + questionB._id"
								class="border-alignement"
								:class="{
									pl5: $vuetify.breakpoint.smAndDown,
									pl30: $vuetify.breakpoint.mdAndUp,
								}"
							>
								<div
									class="mb-2 ortho-questions ortho-bold libelle"
									:id="'libelle' + questionB._id"
									@click="onClickQuestion(questionB, index)"
								>
									<span>{{ questionB.libelle }}</span>
								</div>
								<v-row>
									<v-col
										md="4"
										lg="3"
										sm="6"
										cols="6"
										v-for="(answer, indexAnswer) in questionB.answers"
										v-bind:key="answer"
									>
										<div class="ortho-align-center">
											<div
												class="hvr-grow ortho-questions-answers ortho-questions-answers-null"
												v-bind:ref="questionB._id"
												v-bind:data-id="questionB._id"
												v-bind:data-answer="indexAnswer"
												@click="answerQuestion(questionB)"
											>
												{{ answer }}
											</div>
											<div
												:id="'points-' + answer.trim() + '-' + questionB._id"
												class="ortho-questions-answers-points"
											></div>
										</div>
									</v-col>
								</v-row>
							</div>
						</v-col>
						<Panels
							:question="questionB"
							:countFormat2="countFormat2"
							:index="index"
							@nextQuestion="goToNextQuestion()"
						/>
					</v-row>
				</zoom-center-transition>
				<Alert
					v-if="countFormat2 > questions.length"
					:lengthStories="lengthStories"
					:countStory="countStory"
					:currentScoreStory="currentScore"
					:nbrQuestion="questions.length"
					@goToNextStory="goToNextStory()"
					@showResults="showResults()"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Panels from '../../panels/default/Questions';
import Alert from './Alert-NextStory';
import VueScrollTo from 'vue-scrollto';
import { mapGetters } from 'vuex';
import soundCorrect from '@/assets/sound/goodAnswer.mp3';
import soundError from '@/assets/sound/wrongAnswer.mp3';

export default {
	name: 'Questions',
	props: {
		questions: Array,
		countStory: Number,
		lengthStories: Number,
		storyId: String,
	},
	components: {
		Panels,
		Alert,
	},
	data() {
		return {
			storageKeySound: 'acceptSound',
			countFormat2: 1,
			lighteningGreenSvg: require('@/assets/icons/lightening-green.svg'),
			lighteningGreySvg: require('@/assets/icons/lightening-grey.svg'),
		};
	},
	mounted() {
		window.scrollTo(0, 0);
	},
	computed: {
		...mapGetters('exercice', {
			resultExercice: 'resultExercice',
		}),
		currentScore() {
			let score = 0;
			const currentStoryResult = this.resultExercice.find((element) => {
				return element.story_id == this.storyId;
			});
			if (currentStoryResult) {
				currentStoryResult.questions_id.map((element) => {
					if (element.is_good_answer == true) {
						score++;
					}
				});
			}
			return score;
		},
	},
	methods: {
		async answerQuestion(question) {
			const cookie = window.localStorage.getItem(this.storageKeySound);
			var audioCorrect = new Audio(soundCorrect);
			var audioWrong = new Audio(soundError);
			let elementClicked = event.currentTarget.dataset.id;
			let answerClicked = event.currentTarget.dataset.answer;
			await this.$store.dispatch('exercice/setScoreExercice', {
				idStory: this.storyId,
				answer: question.answers[answerClicked],
				question: question,
			});
			this.$refs[elementClicked].forEach(async (element) => {
				if (element.dataset.answer.trim() === question.good_answer.trim()) {
					if (answerClicked.trim() === question.good_answer.trim()) {
						if (cookie == null) {
							audioCorrect.play();
						}
						element.classList.add('ortho-questions-answers-correct');
						if (document.getElementById('points-' + question.answers[answerClicked].trim() + '-' + question._id)) {
							if (this.countStory <= 1) {
								document.getElementById(
									'points-' + question.answers[answerClicked].trim() + '-' + question._id
								).innerHTML =
									"<span class='ortho-text-green-flash ortho-bold'>+10</span> <img class='lighteningIcons' src='" +
									this.lighteningGreenSvg +
									"'/>";
							} else {
								document.getElementById(
									'points-' + question.answers[answerClicked].trim() + '-' + question._id
								).innerHTML =
									"<span class='ortho-text-green-flash ortho-bold'>+10</span> <img class='lighteningIcons' src='" +
									this.lighteningGreenSvg +
									"'/>";
							}

							//adding animated effect
							document
								.getElementById('points-' + question.answers[answerClicked].trim() + '-' + question._id)
								.classList.add('animated', 'heartBeat', 'slow');
						}
						this.countFormat2++;
					} else {
						element.classList.add('ortho-questions-answers-correct-full');
					}
				} else {
					if (answerClicked.trim() === element.dataset.answer.trim()) {
						if (cookie == null) {
							audioWrong.play();
						}

						element.classList.add('ortho-questions-answers-incorrect-border');
						if (document.getElementById('points-' + question.answers[answerClicked].trim() + '-' + question._id)) {
							document.getElementById(
								'points-' + question.answers[answerClicked].trim() + '-' + question._id
							).innerHTML =
								"<span class='ortho-text-gray ortho-bold'>0</span> <img class='lighteningIcons' src='" +
								this.lighteningGreySvg +
								"'/>";
							//adding animated effect
							document
								.getElementById('points-' + question.answers[answerClicked].trim() + '-' + question._id)
								.classList.add('animated', 'wobble', 'slow');
						}
					} else {
						element.classList.add('ortho-questions-answers-incorrect');
					}
				}

				element.classList.remove('ortho-questions-answers-null');
				element.classList.add('ortho-disable');
			});
			// Update libelle style
			let libelle = document.getElementById('libelle' + question._id);
			let container = document.getElementById('question-answer-container' + question._id);

			//remove gray container when answer to question
			container.classList.remove('border-alignement');

			if (libelle && answerClicked.trim() == question.good_answer.trim()) {
				libelle.classList.remove('ortho-bold');
			}

			//high shadow and low shadow
			let expansionPanelShadow = document.getElementById('expansionPanels' + question._id);
			expansionPanelShadow.classList.remove('shadow-blue-not-openned');
			expansionPanelShadow.classList.add('shadow-blue');

			// Update ExpansionPanels display
			let expansionPanelsDiv = document.getElementById('expansionPanelsDiv' + question._id);

			//if good answer, don't display expansion panel, if not display it
			if (expansionPanelsDiv && answerClicked == question.good_answer) {
				expansionPanelsDiv.classList.add('ortho-display-none');
			} else {
				expansionPanelsDiv.classList.remove('ortho-display-none');
			}

			// VueScrollTo.scrollTo("#question-answer-container" + question._id, {
			//   offset: (VueScrollTo.offset = -60),
			// });

			let removeImgAssistant = document.getElementById('picture-assistant-div');
			removeImgAssistant.classList.add('d-none');

			let showMicroImgAssistant = document.getElementById('micro-picture-assistant');
			showMicroImgAssistant.classList.remove('d-none');

			setTimeout(function () {
				VueScrollTo.scrollTo('#question-answer-container' + question._id, {
					offset: (VueScrollTo.offset = -60),
				});
			}, 400);
		},
		goToNextQuestion() {
			this.countFormat2++;
		},
		goToNextStory() {
			this.$emit('goToFollowingStory');
		},
		showResults() {
			this.$emit('showResults');
		},
	},
};
</script>

<style scoped>
.indicatorIcons-container {
	/* always write it like this for safari */
	display: flex;
	align-items: center;
	justify-content: left;
}
.formatAGlobalContainer {
	padding-left: 10%;
	padding-right: 10%;
}
.assistant-container {
	/* always write it like this for safari */
	display: flex;
	align-items: center;
	justify-content: left;
	margin-top: 20px;
	margin-left: 20px;
}
.ortho-questions-cours-title-closed {
	/* always write it like this for safari */
	display: flex;
	align-items: center;
	justify-content: left;
}
.exemple {
	padding-right: 0px !important;
}
.assistant-icon {
	width: 5%;
	margin-right: 10px;
}
.notification-title {
	margin-left: -80px; /* hardcore désolé, mais pas le choix */
}
#slide-container {
	padding-top: 110px;
	z-index: 1;
}
.container-progress-bar {
	max-width: 75%;
}
.border-alignement {
	border-left-style: solid;
	border-left-color: #efefef;
	border-left-width: 5px;
	padding: 20px 20px 20px 20px;
	background-color: #f7f7f7;
	border-radius: 5px;
}
.cours-title {
	margin-left: 5px;
	font-weight: bold;
}
.indicator-section:hover {
	cursor: default !important;
}
.formatAContainerQuestion {
	margin-top: 50px;
}
.formatA-question {
	font-size: 30px;
}
.formatACoursContainer {
	margin-top: 50px;
}
@media only screen and (max-width: 1300px) {
	.formatACoursContainer {
		margin-top: 50px;
		padding-left: 0px;
		padding-right: 0px;
	}
}
@media only screen and (max-width: 600px) {
	.formatAGlobalContainer {
		padding-left: 12%;
		padding-right: 12%;
	}
	.assistant-icon {
		width: 9%;
	}
	.formatAContainerQuestion {
		margin-top: 10px;
	}
	.formatA-question {
		font-size: 25px;
	}
	.border-alignement {
		padding-top: initial;
		padding-bottom: initial;
		background-color: initial;
		border-radius: initial;
	}
	.container-progress-bar {
		max-width: 100%;
	}
	#slide-container {
		padding-top: initial;
		position: initial;
	}
}
</style>
