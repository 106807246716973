var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"formatAGlobalContainer"},[_c('zoom-center-transition',{attrs:{"group":""}},_vm._l((_vm.questions.slice(_vm.countFormat1 - 1, _vm.countFormat1)),function(questionA,index){return _c('v-row',{key:index},[_c('v-row',{attrs:{"id":'full-formatAContainer' + questionA._id}},[_c('v-col',{attrs:{"md":"4","offset-md":"1","sm":"12","cols":"12"}},[_c('div',{staticClass:"formatAContainerQuestion",attrs:{"id":'formatAContainerQuestion' + questionA._id}},[_c('div',{staticClass:"ortho-align-center"},[_c('div',{staticClass:"ortho-description mb30"},[_c('v-btn',{staticClass:"indicator-section",attrs:{"depressed":""}},[_c('span',[_vm._v("Partie entrainements")])])],1),_c('div',{staticClass:"ortho-description pt20 mb10"},[_c('span',[_vm._v(_vm._s(questionA.action))])]),_c('div',{staticClass:"ortho-questions ortho-bold libelle formatA-question mb20",attrs:{"id":'libelle' + questionA._id}},[_c('span',[_vm._v(_vm._s(questionA.libelle))])])]),_vm._l((questionA.answers),function(answer,indexAnswer){return _c('v-col',{key:answer,attrs:{"md":"12","sm":"12","cols":"12"}},[_c('div',{staticClass:"ortho-align-center"},[_c('div',{ref:questionA._id,refInFor:true,staticClass:"hvr-grow ortho-questions-answers ortho-questions-answers-null",class:{
										'ortho-questions-answers-correct-full':
											_vm.userAnswer != null && indexAnswer == questionA.good_answer,
										'ortho-questions-answers-correct':
											_vm.userAnswer == indexAnswer && indexAnswer == questionA.good_answer,
										'ortho-questions-answers-incorrect-border':
											_vm.userAnswer == indexAnswer && indexAnswer != questionA.good_answer,
										'ortho-questions-answers-incorrect': _vm.userAnswer != null && indexAnswer != questionA.good_answer,
										'ortho-disable': _vm.isDisabled,
									},attrs:{"data-id":questionA._id,"data-answer":indexAnswer},on:{"click":function($event){return _vm.answerQuestionFormatA(questionA, indexAnswer)}}},[_vm._v(" "+_vm._s(answer)+" ")])])])})],2)]),_c('v-col',{staticClass:"formatACoursContainer",class:{
						pb20: _vm.$vuetify.breakpoint.smAndDown,
						pt20: _vm.$vuetify.breakpoint.mdAndUp,
					},attrs:{"md":"4","offset-md":"1","sm":"12","cols":"12"}},[_c('Panels',{staticClass:"formatAContainerQuestion",attrs:{"id":'panel-' + questionA._id,"question":questionA._id,"message":_vm.bravo,"responded":_vm.responded,"allQuestions":_vm.questions,"count":_vm.countFormat1},on:{"ChangeTrainingQuestion":function($event){return _vm.onChangeTrainingQuestion()},"goToQuestions":function($event){return _vm.LeavingTraining()}}})],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }