<template>
	<div
		:class="{
			pa5: $vuetify.breakpoint.smAndDown,
			palr5: $vuetify.breakpoint.mdAndUp,
			mt20: $vuetify.breakpoint.mdAndUp,
		}"
	>
		<div v-if="showPresentationStory" id="section2-3">
			<div class="triangleMaitrise"></div>
			<v-row>
				<v-col offset-md="3" md="6" sm="12" cols="12">
					<div class="ortho-index-container">
						<h5 class="mt-3 mb-4 text-uppercase ortho-text-gray">
							Prochaine astuce - {{ countStory + 1 }}/{{ listStories.length }}
						</h5>
						<h3 class="ortho-ex-index-name ortho-bold mb-3" style="position: relative; z-index: 2">
							{{ infosStory.infos.name }}
						</h3>
						<v-btn color="black" large dark class="hvr-grow mt20 mb20" @click="goToTrainingQuestion()">
							<strong>M’entraîner</strong>
							<v-icon class="ortho-icon-white fs15 ml5">fas fa-arrow-right</v-icon>
						</v-btn>
						<v-row class="mb-1">
							<v-col md="4" cols="12" :class="{ 'order-2': $vuetify.breakpoint.smAndDown }">
								<v-card class="shadow-blue-not-openned pa15">
									<div>
										<h3 class="mb-2">Dernier score</h3>
										<div v-if="performanceExercice.length > 0" class="d-flex align-center justify-center">
											<v-progress-circular
												rotate="90"
												size="35"
												width="6"
												:value="
													(currentScoreStory(performanceExercice).scoreTotal /
														currentScoreStory(performanceExercice).lengthQs) *
													100
												"
												color="#34e5c8"
											>
											</v-progress-circular>
											<span class="ml-3 ortho-text-green-flash font-weight-bold"
												>{{ currentScoreStory(performanceExercice).scoreTotal * 10
												}}<img class="lighteningIcons" src="@/assets/icons/lightening-green.svg" />/{{
													currentScoreStory(performanceExercice).lengthQs * 10
												}}</span
											>
										</div>
										<div v-else>
											<v-progress-circular rotate="90" size="50" width="7" :value="0" color="#dcdcdc">
											</v-progress-circular>
											<span class="ml-3 font-weight-bold" style="color: rgb(220, 220, 220)">En attente</span>
										</div>
									</div>
								</v-card>
							</v-col>
							<v-col md="8" cols="12" class="text-center">
								<v-card
									class="shadow-blue-not-openned pa15 d-flex justify-center align-center flex-column"
									style="height: 100%"
								>
									<h3>{{ infosStory.infos.rule_tags_id.name }}</h3>
									<h4 class="font-weight-regular">{{ randomNumber * 93 - 32 }} personnes maîtrisent cette règle</h4>
								</v-card>
							</v-col>
						</v-row>
						<v-card
							style="word-wrap: break-word; position: relative; z-index: 2"
							class="text-left pa15 shadow-blue-not-openned"
						>
							<h2>💡Le savais-tu ?</h2>
							<p class="ortho-ex-index-description" v-html="infosStory.infos.description"></p>
							<p class="ortho-ex-index-description" v-html="infosStory.infos.sub_description"></p>
						</v-card>
					</div>
				</v-col>
			</v-row>
		</div>
		<div v-else>
			<TrainingQuestions
				:questions="questionsTrainingInStory"
				v-if="!showQuestions"
				@startQuestions="startingQuestions()"
			/>
			<Questions
				:questions="questionsInStory"
				:countStory="countStory"
				:lengthStories="listStories.length - 1"
				:storyId="dataId"
				@goToFollowingStory="goToFollowingStory()"
				@showResults="showResults()"
				v-else
			/>
		</div>
		<Loading :show="loading_show" :label="loading_label[countStory]" />
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TrainingQuestions from '../question-types/default/TrainingQuestions';
import Questions from '../question-types/default/Questions';
import Loading from 'vue-full-loading';

export default {
	name: 'Default',
	data() {
		return {
			showQuestions: false,
			showPresentationStory: true,
			loading_label: ["C'est parti !", 'Allez-vous la maîtriser ?', 'Dernière ligne droite'],
			loading_timeOut: 2000,
			loading_show: false,
		};
	},
	props: {
		dataId: String,
		infosStory: Object,
		countStory: Number,
	},
	components: {
		TrainingQuestions,
		Questions,
		Loading,
	},
	computed: {
		...mapGetters('exercice', {
			questionsInStory: 'questionsInStory',
			questionsTrainingInStory: 'questionsTrainingInStory',
			listStories: 'listStories',
			performanceExercice: 'performanceExercice',
		}),
		randomNumber() {
			const min = 60;
			const max = 70;
			var number = Math.floor(Math.random() * (max - min + 1)) + min;
			return number;
		},
	},
	mounted() {
		window.scrollTo(0, 0);
	},
	created() {
		this.getQuestionsStory(this.dataId);
	},
	methods: {
		...mapActions('exercice', ['getQuestionsStory']),
		goToTrainingQuestion() {
			this.loading_show = true;
			setTimeout(() => {
				this.showQuestions = false;
				this.loading_show = false;
				this.showPresentationStory = false;
			}, this.loading_timeOut);
		},
		startingQuestions() {
			this.showQuestions = true;
			this.showPresentationStory = false;
		},
		goToFollowingStory() {
			this.$emit('changeStory');
		},
		showResults() {
			this.$emit('displayResults');
		},
		currentScoreStory(oldScore) {
			const scoreOld = oldScore.find((element) => {
				return element.story_name == this.infosStory.infos.rule_tags_id.name;
			});
			return scoreOld;
		},
	},
};
</script>

<style scoped>
.textPointIcon {
	margin-left: 20px;
}
.alignChart {
	/* always write it like this for safari */
	display: flex;
	align-items: center;
	justify-content: left;
}
.pointIcon {
	height: 50px;
}
.ortho-ex-index-image-container {
	position: relative;
	z-index: 10;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
}
.ortho-ex-index-image {
	max-width: 70%;
	border-radius: 5px;
}

@media only screen and (max-width: 600px) {
	.textPointIcon {
		margin-left: initial;
	}
}
</style>
