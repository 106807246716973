<template>
	<v-col md="6" sm="12" cols="12">
		{{ audioValidated() }}
		<div v-if="$route.name != 'Story'">
			<div v-if="currentScoreStory / nbrQuestion == 1">
				<v-alert prominent text type="success" color="#686bdf" class="mt20">
					<v-row class="text-center align-center flex-column flex-md-row">
						<v-col class="grow">
							<p class="mb-0">👏 Sans faute !</p>
							<p class="mb-0">Règles en cours de maîtrise</p>
						</v-col>
						<v-col>
							<v-btn v-if="countStory < lengthStories" @click="nextStory()">
								Continuer
								<v-icon class="ortho-icon-white fs15 ml5">mdi-check</v-icon>
							</v-btn>
							<v-btn v-else @click="goToResult()">Voir mes résultats</v-btn>
						</v-col>
					</v-row>
				</v-alert>
			</div>
			<div v-else>
				<v-alert prominent text type="error" icon="mdi-timer-sand" color="grey" class="mt20">
					<v-row class="text-center align-center flex-column flex-md-row">
						<v-col class="grow">Règle en cours de maîtrise</v-col>
						<v-col>
							<v-btn v-if="countStory < lengthStories" @click="nextStory()"> Continuer </v-btn>
							<v-btn v-else @click="goToResult()">Voir mes résultats</v-btn>
						</v-col>
					</v-row>
				</v-alert>
			</div>
		</div>
		<div v-else>
			<div v-if="currentScoreStory / nbrQuestion == 1">
				<v-alert prominent text type="success" color="#686bdf" class="mt20">
					<v-row class="text-center align-center flex-column flex-md-row">
						<v-col class="grow">
							<p class="mb-0">👏 Sans faute !</p>
							<p class="mb-0">Règles en cours de maîtrise</p>
						</v-col>
						<v-col>
							<v-btn @click="goToResult()">Voir mes résultats</v-btn>
						</v-col>
					</v-row>
				</v-alert>
			</div>
			<div v-else>
				<v-alert prominent text type="error" icon="mdi-timer-sand" color="grey" class="mt20">
					<v-row class="text-center align-center flex-column flex-md-row">
						<v-col class="grow">Règle en cours de maîtrise</v-col>
						<v-col>
							<v-btn @click="goToResult()">Voir mes résultats</v-btn>
						</v-col>
					</v-row>
				</v-alert>
			</div>
		</div>
	</v-col>
</template>

<script>
import storyStreak from '@/assets/sound/story-streak.mp3';
import notStoryStreak from '@/assets/sound/not-story-streak.mp3';

export default {
	name: 'Alert-NextStory',
	props: {
		countStory: Number,
		lengthStories: Number,
		currentScoreStory: Number,
		nbrQuestion: Number,
	},
	computed: {
		numberGoodAnswerPercentage() {
			return 100;
		},
	},
	data() {
		return {
			storageKeySound: 'acceptSound',
		};
	},
	methods: {
		audioValidated() {
			const cookie = window.localStorage.getItem(this.storageKeySound);
			var storyStreakAudio = new Audio(storyStreak);
			var notStoryStreakAudio = new Audio(notStoryStreak);
			if (cookie == null) {
				if (this.currentScoreStory / this.nbrQuestion == 1) {
					storyStreakAudio.play();
				} else {
					notStoryStreakAudio.play();
				}
			}
		},
		nextStory() {
			this.$emit('goToNextStory');
		},
		goToResult() {
			this.$emit('showResults');
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('exercice-goToResult');
			}
		},
	},
};
</script>

<style></style>
